@import "../../styles/variables";

.dashboard-wrapper {
    height: 100vh;
    display: flex;
}

.dashboard-wrapper .side-nav {
    position: fixed;
    left: -100%;
    width: 16rem;
    height: 100vh;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
    flex-shrink: 0;
    overflow-y: auto;
    background-color: #ffffff;
    opacity: 0;
    z-index: 999;
    transition: all 0.4s ease;

    &.show-sidebar {
        left: 0;
        opacity: 1;
    }
}

.dashboard-wrapper .side-nav .navigation-wrapper {
    padding: 1em 0;
}

.dashboard-wrapper .side-nav .navigation-wrapper img {
    padding: 1rem 1.5em;
    width: 100%;
}

.dashboard-wrapper .side-nav .navigation-lists {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashboard-wrapper .side-nav .navigation-lists li {
    position: relative;
}

.dashboard-wrapper .side-nav .navigation-lists li.active .navigation-text {
    color: #dc793a;
}

.dashboard-wrapper .side-nav .navigation-lists li.active .navigation-border {
    background-color: #dc793a;
}

.dashboard-wrapper .side-nav .navigation-lists li.active a svg {
    color: #dc793a;
}

.dashboard-wrapper .side-nav .navigation-lists li.active a svg path {
    stroke: #dc793a;
}

.dashboard-wrapper .side-nav .navigation-lists a {
    display: inline-flex;
    align-items: center;
    padding: 1.5rem;
    width: 100%;
    text-decoration: none;
}

.dashboard-wrapper .side-nav .navigation-lists a svg {
    color: #89888c;
}

.dashboard-wrapper .side-nav .navigation-lists .navigation-border {
    position: absolute;
    left: 0;
    width: 0.25rem;
    height: 100%;
    border-radius: 0 0.5rem 0.5rem 0;
}

.dashboard-wrapper .side-nav .navigation-lists .navigation-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: #89888c;
    margin-left: 1rem;
}

.dashboard-content {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.dashboard-content header {
    padding: 1rem 0;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    z-index: 40;
}

.dashboard-content .header-wrapper {
    padding: 0 1.5rem;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.dashboard-content .navigation-button {
    padding: 0.25rem;
    margin-left: -0.25rem;
    margin-right: 1.25rem;
    border-radius: 0.375rem;
    background-color: #ffffff;
    border: none;

    svg {
        color: $color-text-header;
    }
}

.dashboard-content .login-information {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    align-items: center;
    list-style: none;
    margin: 0;
    cursor: pointer;
}

.dashboard-content .login-information.show-dropdown .login-actions {
    top: calc(100% + 1rem);
    visibility: visible;
    opacity: 1;
    z-index: 2;
}

.dashboard-content .user-profile {
    margin-right: 1rem;

    .user-name,
    .user-designation {
        display: block;
    }

    .user-name {
        font-size: 0.875rem;
        font-weight: 600;
        color: $color-text-header;
    }

    .user-designation {
        font-size: 0.75rem;
        font-weight: normal;
        text-transform: capitalize;
        color: $color-text-subtitle;
    }
}

.dashboard-content .user-image {
    position: relative;
    width: 2rem;
    height: 2rem;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    border: solid 1px #89888c;
}

.dashboard-content .user-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.dashboard-content .login-actions {
    position: absolute;
    top: calc(100% - 0.5rem);
    right: 0;
    background-color: #ffffff;
    padding: 0.5rem;
    border: solid 1px rgb(0 0 0 / 5%);
    border-radius: 0 0 5px 5px;
    min-width: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.4s ease;
}

.dashboard-content .login-actions > span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.875rem;
    font-weight: 600;
    color: #89888c;

    &:not(:last-child) {
        margin-bottom: 0.75em;
    }

    svg {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }
}

.dashboard-content .login-actions > span:hover {
    color: $color-primary;
}

.dashboard-menu {
    display: flex;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    background-image: url("../../../public/images/dashboard.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 3em 5em;
    gap: 3em;
}

.dashboard-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 150px;
    height: 150px;
    text-align: center;
    border-radius: 10px;
    background-color: $color-background;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
}

.dashboard-menu-item > * {
    width: 100%;
}

.dashboard-menu-item svg {
    width: 35px;
    height: 35px;
    color: $color-text-header;
    margin-bottom: 0.75em;
}

.dashboard-menu-item > span {
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    text-transform: none;
    color: $color-text-header;
}

.dashboard-menu-item:hover {
    background-color: $color-primary;

    svg,
    > span {
        color: $color-white;
    }

    svg path {
        stroke: $color-white;
    }
}

.dashboard-content-body {
    margin: 2em;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.dashboard-content-body {
    .content-title {
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        text-transform: none;
        margin-bottom: 1em;

        &-main,
        &-sub {
            display: block;
        }

        &-main {
            margin-bottom: 0.5em;
        }

        &-sub {
            font-size: 0.875rem;
            font-weight: 600;
            color: #9f9f9f;

            &:not(:nth-child(2)) {
                margin-top: 0.5em;
            }
        }

        > span:first-child {
            display: block;
            margin-bottom: 0.5em;
        }

        .content-actions {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            button {
                padding: 8px 12px;
                font-size: 14px;
            }

            .loading-svg svg {
                width: 21px;
                height: 21px;
            }

            .content-action-back {
                background-color: $color-black;
            }

            .content-action-submit {
                background-color: $color-green;

                &.submitted {
                    background-color: $color-gray;
                }
            }

            .content-action-print {
                background-color: $color-black;
            }

            .content-action-approve.posted {
                background-color: $color-gray;
            }
        }

        &-entries {
            margin-top: 3em;
            margin-bottom: 0.5em;
        }

        &.change-password {
            margin-top: 2em;
        }
    }

    .content-subtitle {
        font-size: 0.875rem;
        font-weight: 600;
        color: $color-gray;

        &-entries {
            margin-bottom: 1em;
        }
    }
}

.dashboard-content-body .content-add-new button {
    font-size: 14px;
    margin-bottom: 1em;

    svg {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 0.5em;
    }
}

.content-table {
    overflow-x: scroll;

    table {
        width: 100%;

        tbody tr {
            cursor: pointer;

            &:hover {
                background-color: $color-gray-opacity;
            }
        }
    }

    &.table-project tbody tr td:nth-child(n + 4),
    &.table-phase tbody tr td:nth-child(n + 3),
    &.table-task tbody tr td:nth-child(n + 3),
    &.table-employee tbody tr td:nth-child(n + 4),
    &.table-timesheet tbody tr td:nth-child(n + 5),
    &.table-timesheet tbody tr td:nth-child(6),
    &.table-timesheet-period tbody tr td:nth-child(1),
    &.table-timesheet-period tbody tr td:nth-child(n + 4),
    &.table-reimbursement tbody tr td:nth-child(n + 5),
    &.table-reimbursement-entry tbody tr td:nth-child(n + 7) {
        cursor: pointer;
        text-align: center;

        svg {
            width: 18px;
            height: 18px;
        }

        svg:hover {
            color: $color-primary;
        }

        svg:hover path {
            stroke: $color-primary;
        }
    }

    &.table-timesheet tbody,
    &.table-reimbursement tbody {
        td:nth-child(2) {
            > span {
                display: block;
                text-align: center;

                &.status-draft {
                    background-color: $color-gray-opacity;
                }

                &.status-submitted {
                    background-color: $color-primary-opacity;
                }

                &.status-posted {
                    background-color: $color-green-opacity;
                }
            }
        }
    }

    &.table-timesheet-period tbody tr:last-child,
    &.table-reimbursement-entry tbody tr:last-child  {
        background-color: $color-white;
        font-weight: bold;
    }

    &.table-reimbursement-entry tbody tr:last-child td:nth-child(3)  {
        text-align: right;
    }

    &.table-timesheet-period tbody tr.active {
        background-color: $color-primary-opacity;
    }

    &.table-timesheet-period tbody tr td:nth-child(n + 4) {
        div:first-child {
            font-weight: bold;
        }

        div:nth-child(2) {
            font-size: 11px;
        }
    }

    &.table-timesheet-period tbody tr td:last-child {
        font-weight: bold;
    }

    &.table-timesheet-entries thead tr,
    &.table-timesheet-entries tbody td,
    &.table-timesheet-new-entry thead tr,
    &.table-timesheet-new-entry tbody td {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            min-width: 150px;
        }
    }

    &.table-timesheet-entries tbody {
        td:nth-child(1) {
            > span {
                background-color: $color-gray-opacity;
                display: block;
                text-align: center;
                text-transform: capitalize;
                padding: 2px 5px;
            }
        }

        td:last-child {
            svg {
                cursor: pointer;
            }
        }

        td:nth-child(n + 5) {
            font-weight: bold;
            text-align: center;

            &:not(:last-child) {
                svg {
                    &:hover,
                    &.active {
                        color: $color-primary;
                    }
                }

                > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    svg {
                        width: 100%;
                        cursor: pointer;
                    }
                }
            }
        }

        input {
            padding: 8px 12px;
            font-size: 13px;
            border-radius: 5px;
        }
    }

    &.table-timesheet-new-entry tbody {
        button {
            padding: 8px 12px;
            font-size: 14px;

            &:nth-child(2) {
                background-color: $color-black;
            }
        }

        td:nth-child(1) {
            > span {
                background-color: $color-primary-opacity;
                display: block;
                text-align: center;
            }
        }

        td:nth-child(n + 5):not(:last-child) > span {
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
                width: 100%;
                cursor: pointer;

                &:hover {
                    color: $color-primary;
                }
            }
        }

        input,
        select {
            padding: 8px 12px;
            font-size: 13px;
            border-radius: 5px;
        }

        .table-actions {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 10px;
        }
    }
}

.content-pagination {
    margin-top: 0.675em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .page-actions {
        display: flex;
        align-items: center;

        svg {
            width: 18px;
            height: 18px;
            margin: 0 5px;
        }

        input {
            width: 40px;
            padding: 4px 8px;
            font-size: 13px;
            border-radius: 5px;
        }

        .select-first,
        .select-last,
        .select-prev,
        .select-next {
            cursor: pointer;
        }
    }
}

.content-edit {
    margin-top: 3em;

    &-title {
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        text-transform: none;
    }

    .data-edit-content {
        margin-top: 1em;

        > span {
            display: block;
            margin-top: 0.5rem;
        }

        label {
            display: block;
            font-size: 0.875rem;
            font-weight: 600;
            color: #89888c;
            margin-bottom: 0.5rem;
        }

        input,
        select {
            padding: 8px 16px;
            border-radius: 5px;
            font-size: 15px;
        }
    }

    .data-edit-actions {
        text-align: right;
        margin-top: 2em;

        button {
            padding: 8px 12px;
            font-size: 14px;

            &.data-edit-close,
            &.data-edit-reset-password {
                background-color: $color-black;
            }
        }

        button:not(:last-child) {
            margin-right: 10px;
        }

        svg {
            vertical-align: middle;
        }
    }
}

.content-add-new-entry {
    margin: 3em 0;

    button {
        padding: 8px 12px;
        font-size: 14px;
    }
}

.content-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;

    &-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-gray-opacity;
    }

    &-container {
        position: relative;
        left: 50%;
        top: 50%;
        padding: 30px 20px;
        width: 80%;
        max-width: 720px;
        background-color: $color-white;
        border-radius: 5px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        transform: translate(-50%, -50%);
    }

    &-title {
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        text-transform: none;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;

        svg {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px;
            cursor: pointer;

            &:hover {
                color: $color-primary;
            }
        }
    }

    &-body {
        margin-bottom: 1em;
        max-height: 300px;
        overflow-y: scroll;

        p {
            font-size: 15px;
        }
    }

    &-footer {
        text-align: right;

        button {
            padding: 8px 12px;
            font-size: 14px;
        }
    }

    &.popup-timesheet-new {
        textarea {
            width: 100%;
            padding: 14px;
        }
    }
}

.content-print {
    padding: 20px;
    display: none;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-user {
            table {
                border: none;

                tbody td {
                    padding: 0;
                    font-size: 11px;
                    white-space: pre-wrap;
                }

                tbody tr:nth-child(2) td:nth-child(2) {
                    text-transform: capitalize;
                }

                tbody tr {
                    &:nth-child(even) {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &-body {
        table {
            width: 100%;

            thead {
                background-color: $color-gray-table-header;
            }

            thead tr {
                font-size: 11px;
                background-color: $color-gray-table-header;
                color: $color-black;

                th:nth-child(1),
                th:nth-child(2),
                th:nth-child(3) {
                    width: 250px;
                }
            }

            tbody tr {
                font-size: 11px;

                &:nth-child(even) {
                    background-color: transparent;
                }

                &:last-child {
                    font-weight: bold;
                    background-color: $color-gray-table-footer;
                }

                &:not(:last-child) {
                    td {
                        border-bottom: solid 1px $color-gray-opacity;
                    }
                }
            }

            &.table-period {
                tbody tr {
                    td:nth-child(n + 4) {
                        text-align: center;
                    }

                    &:last-child {
                        td:nth-child(3) {
                            text-align: right;
                        }
                    }
                }
            }

            &.table-reimbursement-entry {
                margin-top: 2em;
            }

            &.table-summary {
                tbody tr {
                    td:nth-child(n + 2) {
                        text-align: center;
                    }

                    &:last-child {
                        td:nth-child(1) {
                            text-align: right;
                        }
                    }
                }

                thead tr th:nth-child(n + 2),
                tbody tr td:nth-child(n + 2) {
                    width: 70px;
                }
            }

            &.table-note {
                table-layout: auto;
                width: 100%;

                tbody tr {
                    &:last-child {
                        font-weight: normal;
                        background-color: transparent;
                    }

                    td {
                        &:first-child {
                            width: 1%;
                        }
                    }
                }
            }
        }
    }

    &-table-period {
        display: block;
        margin: 1em 0 0.5em 0;
        font-size: 11px;
        font-weight: bold;
    }

    &-total-hours {
        margin-top: 1em;
        font-size: 12px;
        font-weight: bold;
        text-align: right;
    }

    &-summary,
    &-note {
        margin: 1em 0 0.5em 0;
        font-size: 15px;
        font-weight: bold;
    }

    &-signature {
        display: flex;
        align-items: center;

        &-employee {
            margin-right: 50px;
        }

        &-employee,
        &-manager {
            width: 200px;
        }

        .signature-box {
            width: 100%;
            height: 100px;
            border-bottom: solid 1px $color-black;
            margin-bottom: 0.5em;
        }
    }
}

.content-profile,
.content-profile-password {
    label {
        margin-bottom: 0.5em;
        display: block;
    }

    input[type="text"],
    input[type="password"] {
        padding: 4px 8px;
        font-size: 13px;
        border-radius: 5px;
    }

    > span {
        display: block;

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    button {
        padding: 8px 12px;
        font-size: 14px;
        margin-left: auto;
        margin-top: 1.5em;
        display: block;
    }
}

@media only screen and (min-width: 480px) {
    .dashboard-menu {
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 768px) {
    .dashboard-content-body .content-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        > span:first-child {
            margin-bottom: 0px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .dashboard-wrapper .side-nav {
        position: relative;
        left: 0;
        opacity: 1;
    }

    .dashboard-content .navigation-button {
        display: none;
    }
}

@media print {
    .content-print {
        display: block;
    }
}
