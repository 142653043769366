@import "./styles/variables";

/** RESET CSS **/

*,
:before,
:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:focus,
:focus-visible {
    outline: none;
}

body {
    margin: 0;

    --toastify-color-progress-success: var($color-orange);
    --toastify-color-progress-error: var($color-red);
}

body,
html {
    height: 100%;
}

html {
    background-color: $color-background;
}

iframe,
img {
    border: 0 none;
}

a {
    text-decoration: none;
    color: $color-primary;
}

body {
    color: $color-text-header;
    font-family: $font-family;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.5;
    text-decoration: none;
    text-transform: none;
    overflow: hidden;
}

button,
.button {
    padding: 14px;
    border: none;
    outline: none;
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: $color-white;
    background: $color-primary;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

svg {
    vertical-align: middle;
}

table {
    border: 1px solid $color-table-border;

    th,
    td {
        padding: 5px 15px;
    }

    thead {
        background-color: $color-black;
        color: $color-white;
    }

    tbody tr {
        &:nth-child(even) {
            background-color: $color-table-border;
        }
    }
}

.hidden {
    display: none;
}

/** INPUT CSS **/

input[type="text"],
input[type="password"],
input[type="checkbox"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="search"],
input[type="radio"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea,
select {
    border: 1px solid $color-border;
    border-radius: $input-border-radius;
    margin: unset;
    padding: 14px 31px;
    width: 100%;
    color: $color-text-header;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    &:focus,
    &:active {
        outline: 0;
        box-shadow: none;
        border: 1px solid $color-primary;
    }

    &::placeholder {
        color: $color-text-placeholder;
    }
}

@mixin input-slider-track() {
    height: 4px;
    border: none;
    box-shadow: none;
    background: unset;
}

@mixin input-slider-thumb() {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: $color-primary;
    border: solid 3px $color-white;
    margin-top: -6px;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

input[type="range"] {
    padding: 0;
    margin: unset;
    background: rgba(191, 197, 210, 0.37);
    border-radius: 50px;

    &::-webkit-slider-runnable-track,
    &:focus::-webkit-slider-runnable-track {
        @include input-slider-track;
    }

    &::-moz-range-track,
    &:focus::-moz-range-track {
        @include input-slider-track;
    }

    &::-ms-track,
    &:focus::-ms-track {
        @include input-slider-track;
    }

    &::-webkit-slider-thumb {
        @include input-slider-thumb;
    }

    &::-moz-range-thumb {
        @include input-slider-thumb;
    }

    &::-ms-thumb {
        @include input-slider-thumb;
    }
}

.wrapper {
    padding: 20px;
}

.loader {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: #dc793a;
    letter-spacing: 0.2em;
}

.loader::before,
.loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #dc793a;
    position: absolute;
    animation: load 0.7s infinite alternate ease-in-out;
}

.loader::before {
    top: 0;
}

.loader::after {
    bottom: 0;
}

@keyframes load {
    0% {
        left: 0;
        height: 30px;
        width: 15px;
    }

    50% {
        height: 8px;
        width: 40px;
    }

    100% {
        left: 235px;
        height: 30px;
        width: 15px;
    }
}
