$color-white: #ffffff;
$color-black: #1e1e1e;
$color-gray: #9f9f9f;
$color-orange: #dc793a;
$color-red: #721c24;
$color-green: #7c823f;

$color-background: $color-white;
$color-text-header: $color-black;
$color-text-subtitle: $color-gray;
$color-text-placeholder: $color-gray;
$color-primary: $color-orange;
$color-border: $color-gray;
$color-table-border: #eeeeee;

$color-primary-opacity: rgba(220, 121, 58, 0.3);
$color-gray-opacity: rgba(159, 159, 159, 0.3);
$color-green-opacity: rgba(40, 167, 69, 0.3);
$color-gray-table-header: rgba(159, 159, 159, 0.40);
$color-gray-table-footer: rgba(159, 159, 159, 0.20);

$font-family: Poppins, sans-serif;
$input-border-radius: 10px;
