@import '../../styles/variables';

.login-wrapper {
    display: flex;
    align-items: center;
    height: 100vh;
}

.login-image,
.login-form {
    flex: 50%;
}

.login-image {
    width: 100%;
    height: 100%;
    background-image: url('../../../public/images/dashboard.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.login-form {
    display: flex;
    justify-content: center;
}

.login-form form {
    width: 350px;
    padding: 15px;
    text-align: center;
}

.login-form img {
    width: 100%;
    max-width: 350px;
    margin-bottom: 2em;
}

.login-form form input,
.login-form form button[type="submit"] {
    width: 100%;
}

.login-form label {
    text-align: left;
    display: block;

    p {
        margin-bottom: 0.875em;
    }
}

.login-form label:nth-child(2) {
    margin-bottom: 2em;
}

.login-form form button[type="submit"] {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    height: 48px;
    color: #FFFFFF;
    border: none;
    background: #DC793A;
    border-radius: 4px;
    margin-top: 2em;
    cursor: pointer;
}

.login-form form button[type="submit"]:hover {
    opacity: 0.8;
}

.login-form form button[type="submit"] span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.login-form form button[type="submit"] svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.login-form form label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    color: #50565F;
}

.login-form form input {
    border: 1px solid #D3D7DF;
    border-radius: 4px;
    padding: 12px;
}

.login-form .login-notice {
    width: 100%;
    display: inline-block;
    text-align: center;
    color: $color-red;
    background-color: #f8d7da;
    border-radius: 5px;
    border: 1px solid #f5c6cb;
    margin-top: 2em;
    padding: 15px;
}

@media only screen and (max-width: 1023px) {
    .login-image {
        display: none;
    }

    .login-form {
        position: relative;
        height: 100vh;
        background-image: url('../../../public/images/dashboard.jpeg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .login-form form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        padding: 30px;
        border-radius: 15px;
        width: calc(100% - 120px);
    }
}